import React, { useEffect } from 'react'
import styled from 'styled-components'
import { mq } from '../styles/theme'
import Layout from '../components/Layout/layout'
import Form from '../components/Form/ForgotPassword'
import OrbImage from '../components/OrbImage';
import Rtf from '../components/Rtf/Article'
import { isAuthSSO } from '../services/auth'
import { navigate } from 'gatsby'

const ForgotPassword = ({ pageContext }) => {
  const {
    PageTitle,
    PageSuccessMessage,
    PageMainContent
  } = pageContext

  useEffect(() => {
    isAuthSSO() && navigate('/')
  }, [])

  return (
    <>
      {
        !isAuthSSO() &&
        <Layout>
          <FormBlock>
            <OrbElementTop>
              <OrbImage type={'nocolor-large'}/>
            </OrbElementTop>
            <OrbElementBottom>
              <OrbImage type={'nocolor-large'}/>
            </OrbElementBottom>
            <FormContainer>
              <FormCopyWrapper>
                <FormHeading>{PageTitle}</FormHeading>
                <FormCopy><Rtf copy={PageMainContent.processed}/></FormCopy>
              </FormCopyWrapper>
              <FormWrapper>
                <Form successText={PageSuccessMessage}/>
              </FormWrapper>
            </FormContainer>
          </FormBlock>
        </Layout>
      }
    </>
  )
}

export default ForgotPassword

export { Head } from '../components/_GatsbyPageHead'

const FormBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-block-size: 749px;
  position: relative;
  overflow: hidden;
`

const FormContainer = styled.div`
  margin-inline: 20px;
  max-inline-size: 550px;
  width: 100%;

  ${mq.tablet} {
    margin-inline: 200px;
  }

  ${mq.large} {
    margin-inline: 350px;
  }
`

const FormWrapper = styled.div`
  max-inline-size: 400px;
  margin: auto;
`

const FormCopyWrapper = styled.div`
  margin-block-end: 35px;
`

const FormHeading = styled.h2`
  font-family: "BuckeyeSerif";
  font-weight: 700;
  font-size: 28px;
  margin-block-end: 12px;
  text-align: center;

  ${mq.desktop} {
    font-size: 48px;
    margin-block-end: 20px;
  }
`

const FormCopy = styled.p`
  text-align: center;
`

const OrbElementTop = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 500px;
  position: absolute;
  transform: translate(-50%,0%);

  ${mq.desktop} {
    display: block;
  }
`

const OrbElementBottom = styled.div`
  display: none;
  bottom: 0;
  right: 0;
  width: 600px;
  position: absolute;
  transform: translate(60%, 10%);

  ${mq.desktop} {
    display: block;
  }
`
