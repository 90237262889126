import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import Button from '../Button'
import ServerMessage from '../ServerMessage'
import Link from '../Link'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import { handleForgotPassword } from '../../services/auth'

function FormikWrapper({ successText }) {
  const [triggeredError, setTriggeredError] = useState(false)
  const [triggeredSuccess, setTriggeredSuccess] = useState(false)

  const initialValues = {
    email: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is a required field').email('Please enter a valid email address.')
  })

  const onSubmit = values => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const res = await handleForgotPassword(values.email)
        // eslint-disable-next-line no-undefined
        setTriggeredError(!res)
        setTriggeredSuccess(res)

      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <Form method="post">
          {
            triggeredError &&  <ServerMessage messagetype='error' triggered={true} errorMessage='Incorrect username or password.'/>
          }
          {
            triggeredSuccess && <ServerMessage messagetype='success' triggered={triggeredSuccess} successText={successText.processed}/>
          }
          <FormController
            control="input"
            type="email"
            label="Email"
            name="email"
            error={ formik.errors.email }
          />
          <FormButton isSubmit text="Reset Password" />
          <FormLink type='primary' href='/app/login' text='Go back to sign in.'/>
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper

const FormButton = styled(Button)`
  margin-block-start: 7px;
  margin-block-end: 30px;
  width: 100%;

  ${mq.desktop} {
    margin-block-end: 20px;
  }
`

const FormLink = styled(Link)`
  justify-content: center;
  line-height: 1.5;
`
